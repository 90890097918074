import { graphql } from "gatsby";
import React from "react";
import IspPage from "../../components/pages/ispirazioni";

const Ispirazioni = (props) => <IspPage {...props} />;

export const query = graphql`
  query IspirazioniIta {
    ispirazioni: allStoryblokEntry(
      filter: {
        lang: { eq: "default" }
        field_component: { eq: "ispirazione" }
      }
    ) {
      nodes {
        slug
        name
        lang
        field_component
        tag_list
        content
      }
    }
  }
`;

export default Ispirazioni;
